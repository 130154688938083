import React from "react"
import "twin.macro"

// Components
import Layout from "@components/layout"
import Container from "@components/container"
import Seo from "@components/seo"
import SubscribeForm from "@components/subscribeForm"

// Assets
import logo from "@images/logos/xywav-logo.svg"

const StayConnected = ({ location }) => {
  return (
    <Layout mainBgColour="lilac" location={location}>
      <Seo
        title="Stay Connected for XYWAV® Narcolepsy Information | XYWAV®"
        description="Sign up to receive helpful information about narcolepsy and XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution via email. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <section tw="relative pt-16 md:pt-24 xl:(pt-32)">
        <Container>
          <img
            src={logo}
            alt=""
            tw="hidden lg:(block absolute w-[535px] right-[-220px] top-0)"
          />
          <p tw="font-extrabold uppercase text-2xl">UNLOCK MORE SUPPORT</p>
          <h1 tw="font-cocon font-semibold text-5xl leading-[1.2] mt-2 mb-4 xl:(text-6xl mt-4 max-w-2xl leading-[1.15])">
            More resources await you
          </h1>
          <p tw="lg:(max-w-[770px])">
            Sign up to hear from real XYWAV patients on their treatment
            experience, get expert perspectives on living with and managing
            narcolepsy, and learn about JazzCares<sup>&reg;</sup> financial
            support. Get&nbsp;all that and more by signing up below.
          </p>
          <div tw="mt-6 pb-8 lg:(mt-16 pb-10)">
            <SubscribeForm />
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default StayConnected
